<template>
  <div>
    <div class="pageBars">
      <div
        @click="setPage(i + 1)"
        :class="currentPage - 1 == i ? 'active' : 'white'"
        v-for="(page, i) in pageAmount"
        :key="i"
        class="pageSection"
      >
        <p
          :style="
            currentPage - 1 == i ? 'color:white;' : 'color:' + accent + ';'
          "
          class="titleDisplay"
        >
          {{ titles[i] }}
        </p>
      </div>
    </div>
  </div>
</template>
 
<script>
import { accent } from './../../assets/css/variables/theme.scss'

export default {
  props: ['setPage', 'pageAmount', 'currentPage', 'titles'],
  computed: {
    accent() {
      return accent
    },
  },
}
</script>

<style scoped lang="scss">
.pageBars {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}
.pageSection {
  cursor: pointer;
  z-index: 3;
  position: relative;
  width: 45%;
  background: white;
  border: 1px solid $accent;
  border-radius: 10px;
}
.pageStar {
  position: absolute;
  transform-origin: center;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  backface-visibility: hidden;
  background: $accent;
  z-index: 20;
  left: -15px;
}

.titleDisplay {
  position: relative;
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  font-size: 9pt;
  text-align: center;
  padding: 10px;
}

.fill {
  background: $accent;
}

.border {
  background: white;
  border-top: 1px solid $accent;
  border-right: 1px solid $accent;
}

.currentPageBar {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
}
.currentPage {
  transform-origin: center;
  z-index: 3;
  position: relative;
  height: 40px;
  width: 40px;
  margin: auto;
  transform: rotate(45deg);
  background: #ffffff;
  border-top: 1px solid rgb(223, 223, 223);
  border-left: 1px solid rgb(223, 223, 223);
  top: 20px;
}

.active {
  background: $accent;
}

.white {
  background: white;
}

.dontDisplay {
  opacity: 0;
}
</style>