<template>
  <div class="pageWrapper">
    <div class="topPart">
      <section class="pageData">
        <div class="topTitle">
          <H2>Edit order</H2>
          <div>
            <MainButton
              :clickFunction="() => this.$router.push({ name: 'Orders' })"
              styleClass="secondary"
              title="Cancel"
            />
            <MainButton
              :clickFunction="() => updateOrder()"
              styleClass="primary"
              title="Update"
              :buttonStyle="{ height: '30px', 'margin-left': '10px' }"
            />
          </div>
        </div>
        <CustomerBlock :userData="userData" v-if="userData" />
        <br />
        <!-- Step bar -->
        <div class="stepBar">
          <!-- <StepBar
            v-if="isMobile"
            :pageAmount="pageAmount"
            :currentPage="currentPage"
            :titles="['Products', 'Delivery information']"
          /> -->
          <!-- <PageBar v-else :pageAmount="pageAmount" :currentPage="currentPage" :titles="['Add products', 'Delivery information', 'Confirm']" /> -->
          <TabBar
            :setPage="setPage"
            :pageAmount="pageAmount"
            :currentPage="currentPage"
            :titles="['Products', 'Delivery information']"
          />
        </div>
      </section>
    </div>
    <div class="orderModal">
      <!-- Current page -->
      <section class="pageData">
        <div>
          <TabProducts :products="products" v-if="currentPage == 1" />
          <TabDelivery
            :updateDateValues="updateDateValues"
            :shops="shops"
            :orderInformation="orderInformation"
            v-if="currentPage == 2"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import MainButton from '@/layout/MainButton'
import AddNewCustomer from '@/components/customers/AddNewCustomer'

import TabProducts from '@/components/orders/addOrder/TabProducts'
import TabDelivery from '@/components/orders/addOrder/TabDelivery'
import StepBar from '@/components/bars/StepBar'
import PageBar from '@/components/bars/PageBar'
import TabBar from '@/components/bars/TabBar'
import CustomerBlock from '../../components/customers/CustomerBlock'
import H2 from '../../layout/typography/H2'
import { formatDate } from '../../globalFunctions/date'

export default {
  components: {
    AddNewCustomer,
    MainButton,
    TabProducts,
    TabDelivery,
    StepBar,
    PageBar,
    TabBar,
    CustomerBlock,
    H2,
  },
  data() {
    return {
      error: '',
      currentPage: 1,
      pageAmount: 2,
      products: [],
      userData: undefined,
      shops: undefined,
      orderInformation: {
        dateMonth: undefined,
        dateYear: undefined,
        dateDay: undefined,
        pickupDeilveryTimeStart: '',
        pickupDeilveryTimeEnd: '',
        pickupDeliveryMethod: undefined,
        store: undefined,
        paymentMethod: undefined,
        note: '',
      },
    }
  },
  created() {
    this.getOrderData()
    this.getOrderInfo()

    axios.get('storage/getCompanyShops').then((res) => {
      this.shops = res.data
    })
  },
  watch: {},
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    getOrderInfo() {
      axios
        .post('orders/getOrderInfo', {
          orderId: this.$route.params.orderID,
        })
        .then((res) => {
          this.orderInformation = res.data.orderInformation
          this.userData = res.data.userData
        })
    },
    getOrderData() {
      axios
        .post('orders/getOrderData', {
          orderId: this.$route.params.orderID,
        })
        .then((res) => {
          this.products = res.data
        })
    },
    updateDateValues(date) {
      if (date) {
        let values = String(date).split('-')
        this.orderInformation.dateYear = String(values[0])
        this.orderInformation.dateMonth = String(values[1])
        this.orderInformation.dateDay = String(values[2])
      }
    },
    validate() {
      let foundError = false
      this.products.forEach((element) => {
        if (element.productUnit == 'undefined') {
          this.error = element.productName + ' is missing a unit'
          this.errorPage = 1
          foundError = true
        }
      })
      if (foundError) {
        return false
      }

      if (this.products.length == 0) {
        this.error = 'No product added'
        this.errorPage = 1
        return false
      }

      if (this.orderInformation.dateMonth == undefined) {
        this.error = 'No month selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.dateYear == undefined) {
        this.error = 'No year selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.dateDay == undefined) {
        this.error = 'No day selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.pickupDeilveryTimeStart == '') {
        this.error = 'No pickuptime selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.pickupDeliveryMethod == undefined) {
        this.error = 'No delivery method selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.store == undefined) {
        this.error = 'No store selected!'
        this.errorPage = 2
        return false
      }
      if (this.orderInformation.paymentMethod == undefined) {
        this.error = 'No payment method selected!'
        this.errorPage = 2
        return false
      }

      return true
    },
    displayError() {
      alert(this.error)
    },
    formatDate,
    changePage(val) {
      this.currentPage += val
    },
    setPage(val) {
      this.currentPage = val
    },
    setUserData(data) {
      this.userData = data
    },
    updateOrder() {
      if (this.orderInformation.status == 2) {
        //Update order status to 1
        this.$socket.emit('changeOrderStatus', {
          status: 1,
          orderId: this.orderInformation.orderId,
        })
      }

      if (this.validate() == false) {
        this.setPage(this.errorPage)
        return alert(this.error)
      }

      let newOrder = new Object()
      let dateObj = new Date(
        this.orderInformation.dateMonth +
          '/' +
          this.orderInformation.dateDay +
          '/' +
          this.orderInformation.dateYear
      )
      newOrder.userId = this.userData.id
      newOrder.companyId = this.$route.params.companyId
      newOrder.paymentMethod = this.orderInformation.paymentMethod
      newOrder.deliveryMethod = this.orderInformation.pickupDeliveryMethod
      newOrder.datePickup = this.formatDate(dateObj)
      if (this.orderInformation.pickupDeilveryTimeEnd == '') {
        newOrder.timePickupDelivery = String(
          this.orderInformation.pickupDeilveryTimeStart
        )
      } else {
        newOrder.timePickupDelivery =
          String(this.orderInformation.pickupDeilveryTimeStart) +
          ' - ' +
          String(this.orderInformation.pickupDeilveryTimeEnd)
      }
      newOrder.shopId = this.orderInformation.store
      newOrder.otherInfo = this.orderInformation.note
      newOrder.creator = this.$store.state.selectedUser.id
      newOrder.orderId = this.$route.params.orderID

      newOrder.products = this.products

      this.$socket.emit('editOrder', newOrder)

      this.$router.push({ name: 'Orders' })
    },
  },
}
</script>

<style scoped>
.orderInfoBox {
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(223, 223, 223);
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.topTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.rightBox {
  max-width: 1000px;
}
.orderInfoSection {
  display: flex;
  margin: 2px 0;
}
.orderInfoSection h6 {
  width: 60px;
  font-size: 10pt;
}
.topPart {
  position: relative;
  max-width: 850px;
  height: auto;
  display: flex;
  margin: auto;
}

.buttons {
  position: absolute;
  bottom: -45px;
  right: 0;
  float: right;
}

.title {
  position: relative;
  margin: auto 0;
}

.orderModal {
  padding: 40px;
  position: relative;
  max-width: 850px;
  min-height: 450px;
  border-radius: 10px;
  background: white;
  display: flex;
  margin: auto;
  border: 1px solid rgb(223, 223, 223);
  padding-bottom: 70px;
}

.pageData {
  position: relative;
  width: 100%;
}
.buttons span {
  margin: 5px;
}
.stepBar {
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .buttons {
    width: 100%;
    display: flex;
  }
}
</style>
